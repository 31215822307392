import cn from 'classnames'
import React, { FC } from 'react'

import getTestId from '../../helpers/getTestId'

const ModalFooter: FC<{ className?: string; testId?: string }> = ({
  className,
  children,
  testId,
}) => (
  <div
    className={cn('modal__footer', { [`${className}`]: !!className })}
    data-testid={getTestId('modal-footer', testId)}>
    {children}
  </div>
)

export default ModalFooter
