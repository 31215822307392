import cn from 'classnames'
import { FC } from 'react'

import getTestId from '../../helpers/getTestId'

const ModalHeader: FC<{ testId?: string; className?: string }> = ({
  testId,
  className,
  children,
}) => {
  return (
    <div
      className={cn('modal__header', { [`${className}`]: !!className })}
      data-testid={getTestId('modal-header', testId)}>
      {children}
    </div>
  )
}

export default ModalHeader
