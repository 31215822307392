import cn from 'classnames'
import React, { FC } from 'react'

import getTestId from '../../helpers/getTestId'

const ModalBody: FC<{ className?: string; testId?: string }> = ({
  className,
  children,
  testId,
}) => (
  <div
    className={cn('modal__body', { [`${className}`]: !!className })}
    data-testid={getTestId('modal', testId)}>
    {children}
  </div>
)

export default ModalBody
