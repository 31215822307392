import cn from 'classnames'
import React, { FC } from 'react'

import getTestId from '../../helpers/getTestId'

const Modal: FC<{ className?: string; testId?: string }> = ({ children, className, testId }) => (
  <div
    className={cn('modal', { [`${className}`]: !!className })}
    data-testid={getTestId('modal', testId)}>
    {children}
  </div>
)

export default Modal
